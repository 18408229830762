<script lang="ts" setup>
import { ParagraphShopStartpageInfo } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphShopStartpageInfo
}>()
const items =
  props.entity.fieldLinks?.map((item, index) => {
    return {
      id: index,
      link: {
        text: item?.title || '',
        url: item?.url?.path || '',
      },
    }
  }) || []
</script>

<template>
  <Section name="search">
    <ShopSearch class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1" />
  </Section>
  <SectionIntrotext v-if="entity.fieldText?.value" :text="entity.fieldText?.value" />
  <Section name="categories">
    <LinkList class="subcategories t-10 t-o-1 tl-7 tl-o-0 ds-o-1" :items="items" />
  </Section>
</template>
<style scoped lang="scss">
.subcategories {
  display: grid;
  gap: 0 var(--gap);
  @include breakpoint(ts) {
    grid-template-columns: 1fr 1fr;
  }
  .link {
    color: var(--c-primary);
    font-weight: 400;
  }
}
</style>
